import Vue from "vue";
import KTPanel from "@/view/content/Panel.vue";

window.inabex = {};

Vue.prototype.$vueVersion = Vue.version;

Vue.prototype.hasActiveChildren = match => {
  return window.inabex.vm.$route["path"].indexOf(match) !== -1;
};

Vue.component("KTPanel", KTPanel);
