import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import UserInfoService from "@/core/services/userinfo.service";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

import "./helpers/globals";

import UUID from 'vue-uuid';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
const options = {
  confirmButtonColor: '#3699FF',
  cancelButtonColor: '#d33',
};

Vue.use(VueSweetalert2, options);

Vue.use(UUID);
Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
//import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
//import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
//import "@/core/plugins/apexcharts";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";

// API service init
ApiService.init();

// Remove this to disable mock API
// MockService.init();

router.beforeEach(async (to, from, next) => {
  // Ensure we checked auth before each page load.
  if (to.name != "login") // all page require auth apart from login
  {
    const token = JwtService.getToken();
    if (!token) // no token
    {
      next({ name: 'login' });
    }
    else {
      ApiService.setHeader();
      const response = await ApiService.get("/v1/Pings/Authorize");
      if (response.status != 200) // token expired
      {
        JwtService.destroyFullToken();
        UserInfoService.destroyUserInfo();
        next({ name: 'login' });
      }
      else // token valid
      {
        next();
      }
    }
  }
  else {
    next();
  }

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

window.inabex.vm = new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
