const TOKEN_INFO_KEY = "token_info";

export const getToken = () => {
  const tokenInfo = window.localStorage.getItem(TOKEN_INFO_KEY);
  if (tokenInfo != undefined)
  {
    return JSON.parse(tokenInfo).accessToken;
  }
  return undefined;
};

export const getRefreshToken = () => {
  const tokenInfo = window.localStorage.getItem(TOKEN_INFO_KEY);
  if (tokenInfo != undefined)
  {
    return JSON.parse(tokenInfo).refreshToken;
  }
  return undefined;
};

export const saveFullToken = token => {
  window.localStorage.setItem(TOKEN_INFO_KEY, JSON.stringify(token));
};

export const destroyFullToken = () => {
  window.localStorage.removeItem(TOKEN_INFO_KEY);
};

export default { getToken, getRefreshToken, saveFullToken, destroyFullToken };
