<template>
  <KTCard ref="preview" v-bind:title="title">
    <template v-slot:body>
      <slot></slot>
    </template>
  </KTCard>
</template>

<script>
import KTCard from "@/view/content/Card.vue";

export default {
  name: "KTPanel",
  props: {
    title: String
  },
  components: {
    KTCard
  }
};
</script>
