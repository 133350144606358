import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/texts",
      redirect: "/texts/list",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "list",
          name: "all-texts",
          component: () => import("@/view/pages/texts/List.vue")
        },
        {
          path: "create",
          name: "create-text",
          component: () => import("@/view/pages/texts/Create.vue")
        },
        {
          path: "translations",
          name: "text-translations",
          component: () => import("@/view/pages/texts/Translations.vue"),
          props: route => ({ textGuid: route.query.textGuid })
        },
      ]
    },
    {
      path: "/users",
      redirect: "/users/all",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "all",
          name: "all-users",
          component: () => import("@/view/pages/utilisateurs/List.vue")
        },
        {
          path: "create",
          name: "create-user",
          component: () => import("@/view/pages/utilisateurs/Create.vue")
        }
      ]
    },
    {
      path: "/translations",
      redirect: "/translations/all",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "all",
          name: "all-translations",
          component: () => import("@/view/pages/traductions/List.vue")
        },
        {
          path: "one",
          name: "one-translation",
          component: () => import("@/view/pages/traductions/One.vue"),
          props: route => ({ translationGuid: route.query.translationGuid })
        },
        {
          path: "waiting",
          name: "translations-waiting",
          component: () => import("@/view/pages/traductions/EnAttente.vue")
        },
        {
          path: "valid",
          name: "translations-valid",
          component: () => import("@/view/pages/traductions/Valid.vue")
        }
      ]
    },
    {
      path: "/",
      redirect: "/login",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    }
  ]
});
