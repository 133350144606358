const USER_INFO_KEY = "user_info";

export const getUserInfo = () => {
  return JSON.parse(window.localStorage.getItem(USER_INFO_KEY));
};

export const saveUserInfo = info => {
  window.localStorage.setItem(USER_INFO_KEY, JSON.stringify(info));
};

export const destroyUserInfo = () => {
  window.localStorage.removeItem(USER_INFO_KEY);
};

export default { getUserInfo, saveUserInfo, destroyUserInfo };
